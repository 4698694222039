import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Link } from 'gatsby'

const YearStyles = styled.div`
  text-align: center;

  .year {
    font-size: 5rem;
    text-decoration: underline;
  }

  .entries {
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
`

const EntryStyles = styled.div`
  /* margin: 20px 0; */
  /* margin: 30px auto; */
  /* margin-bottom: 30px; */
  width: 40%;
  min-width: 300px;
  /* padding: 10px 20px;
  border: 1px solid black;
  box-shadow: 2px 2px 5px #999; */

  .title {
    text-decoration: underline;
    margin-bottom: 5px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    .chapter {
      margin-bottom: 10px;
    }
  }

  .top-info,
  .bottom-info {
    margin-bottom: 20px;
  }

  .image-container {
    /* height: 100px; */
    width: 50%;
    margin: 15px auto;
    /* overflow: hidden; */
    /* margin: 0 auto; */

    img {
      margin: 0 auto;
      /* height: 100%; */
      width: 70%;
    }
  }
`

const arrangeInYears = groups =>
  groups.reduce((obj, group) => {
    const { year } = group
    if (!obj[year]) {
      obj[year] = []
    }
    obj[year].push(group)

    return obj
  }, {})

const ChampionGroup = ({ entry, showCat, showLine }) => {
  const catShow = showCat ? <h4>{entry.category}</h4> : ''

  return (
    <EntryStyles className="item-wrapper">
      <Link to={entry.slug.current}>
        <div className="top-info">
          <h2>{entry.title}</h2>
          {catShow}
        </div>
        <div className="image-container">
          <Img fluid={entry.image.asset.fluid} alt={entry.image.alt} />
        </div>
        <div className="bottom-info">
          <ul>
            <li className="chapter">Chapter: {entry.chapter}</li>
          </ul>
        </div>
      </Link>
    </EntryStyles>
  )
}

export default function MobileChamps({ entries, showCat }) {
  const entriesArrangedInYears = arrangeInYears(entries)
  const years = Object.keys(entriesArrangedInYears).sort((a, b) => b - a)

  return years.map((year, i) => (
    <YearStyles key={year}>
      <h2 className="year">{year}</h2>
      <div className="entries">
        {entriesArrangedInYears[year].map(entry => (
          <ChampionGroup
            key={`${entry.id}-mobile`}
            entry={entry}
            showCat={showCat}
            showLine={i !== years.length - 1}
          />
        ))}
      </div>
    </YearStyles>
  ))
}
