import { navigate } from 'gatsby'
import React from 'react'

import {
  BiFirstPage as FirstPage,
  BiLastPage as LastPage,
  BiChevronLeft as PrevPage,
  BiChevronRight as NextPage,
} from 'react-icons/bi'
import styled from 'styled-components'

import { getSearchParams } from '../../utils/utils'

const PageinationStyles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .nav-buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .nav-wrapper {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    cursor: pointer;

    font-size: 3rem;
    background-color: var(--link-hover);
    color: white;
    border: 1px solid black;

    &.disabled {
      pointer-events: none;
      background-color: #777;
      opacity: 0.7;
    }

    .page-nav {
      pointer-events: none;
      /* padding: 5px; */
      svg path {
        /* pointer-events: none; */
      }
    }
  }
`

const handleNav = (e, location, basePath) => {
  const { toindex: toIndex, curindex: curIndex } = e.target.dataset

  if (toIndex !== curIndex && toIndex !== 0) {
    const navTo = toIndex <= 1 || !toIndex ? '/' : `/${toIndex}`
    const searchParams = getSearchParams({ location }) || ''

    let searchString = ''

    if (searchParams) {
      const paramString = Object.entries(searchParams).map(
        item => `${item[0]}=${encodeURIComponent(item[1])}`
      )

      searchString = `?${paramString.join('&')}`
    }

    navigate(`${basePath}${navTo}${searchString}`, {
      replace: true,
    })
  }
}

export default function PageNavigationWrapper({
  pageInfo,
  location,
  basePath,
}) {
  const { currentPage, hasNextPage, pageCount, hasPreviousPage } = pageInfo

  return (
    <PageinationStyles>
      <div className="nav-buttons">
        <button
          type="button"
          className={`nav-wrapper ${hasPreviousPage ? '' : 'disabled'}`}
          onClick={e => {
            handleNav(e, location, basePath)
          }}
          data-toindex="1"
          data-curindex={currentPage}
        >
          <FirstPage className="page-nav" />
        </button>
        <button
          type="button"
          className={`nav-wrapper ${hasPreviousPage ? '' : 'disabled'}`}
          onClick={e => {
            handleNav(e, location, basePath)
          }}
          data-toindex={currentPage - 1}
          data-curindex={currentPage}
        >
          <PrevPage className="page-nav" />
        </button>
      </div>
      <h4>
        {currentPage} of {pageCount}
      </h4>
      <div className="nav-buttons">
        <button
          type="button"
          className={`nav-wrapper ${hasNextPage ? '' : 'disabled'}`}
          onClick={e => {
            handleNav(e, location, basePath)
          }}
          data-curindex={currentPage}
          data-toindex={currentPage + 1}
        >
          <NextPage className="page-nav" />
        </button>
        <button
          type="button"
          className={`nav-wrapper ${hasNextPage ? '' : 'disabled'}`}
          onClick={e => {
            handleNav(e, location, basePath)
          }}
          data-toindex={pageCount}
          data-curindex={currentPage}
        >
          <LastPage className="page-nav" />
        </button>
      </div>
    </PageinationStyles>
  )
}
