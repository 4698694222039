import { graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import DesktopView from '../../components/HallOfFame/DesktopView'
import MobileView from '../../components/HallOfFame/MobileView'

import PageNavigationWrapper from '../../components/pagination/navWrapper'

const MainContentSyles = styled.div`
  .title {
    margin-bottom: 30px;
  }

  .table-title {
    margin-bottom: 15px;
    text-align: center;
  }

  .mobile-version {
    display: block;
  }

  .desktop-version {
    display: none;
  }

  .content {
    margin-top: 25px;
  }

  @media all and (min-width: 1136px) {
    .mobile-version {
      display: none;
    }

    .desktop-version {
      display: block;
    }
  }
`

export default function HallOfFame({ data, location }) {
  const { nodes: entries, pageInfo } = data.entries

  return (
    <MainContentSyles className="content-width">
      <div>
        <h1 className="title">Hall of Fame</h1>
        <p>
          <Link
            class="dark"
            rel="canonical"
            to="/news/hall-of-fame-nomination-details"
          >
            For information on how to nominate your favorite Pioneer member to
            the hall of fame, click HERE
          </Link>
        </p>
        <p>
          Click on a person to see more information about why they were added to
          the hall of fame.
        </p>
      </div>
      <PageNavigationWrapper
        location={location}
        pageInfo={pageInfo}
        basePath="/history/hall-of-fame"
      />
      <div className="desktop-version">
        <DesktopView entries={entries} />
      </div>
      <div className="mobile-version">
        <MobileView entries={entries} showCat />
      </div>
    </MainContentSyles>
  )
}

export const query = graphql`
  query HallOfFameQuery($skip: Int!, $limit: Int!) {
    entries: allSanityHallOfFame(
      sort: { fields: year, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      pageInfo {
        currentPage
        hasNextPage
        pageCount
        hasPreviousPage
      }
      nodes {
        id
        slug {
          current
        }
        title
        year
        chapter
        image {
          alt
          asset {
            fixed(width: 200) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
