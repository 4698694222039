import React, { Component } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { navigate } from 'gatsby'

const ChampListStyles = styled.div`
  overflow: scroll;
  font-size: 120%;
  text-align: center;
  width: 80%;
  margin: 0 auto;

  table {
    margin: 0 auto;
    width: 100%;
    border-collapse: collapse;
  }

  table {
    border: 1px solid black;

    thead tr th {
      padding: 10px;
    }

    tbody tr td {
      padding: 0 5px;

      :nth-child(5) {
        padding: 0;
      }
    }

    td,
    th {
      border: 1px solid #ddd;
    }
  }

  tbody tr {
    pointer-events: auto;
    cursor: pointer;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tbody tr:hover {
    background-color: #ddd;
  }

  table {
    thead {
      padding: 0;
      margin: 0;
      background-color: var(--link-hover);
      color: white;
    }
  }

  .group-info {
    /* text-align: left; */
    padding: 0;
    margin: 0;
    list-style: none;
  }
`

const navigationHandler = slug => {
  navigate(`/history/hall-of-fame/${slug}`)
}

class HallOfFameTableComponent extends Component {
  constructor({ entries }) {
    super()
    this.entries = entries
    this.rows = this.createRows()
  }

  createRows() {
    const { entries } = this

    return entries.map(entry => (
      <tr
        key={entry.id}
        onClick={() => {
          navigationHandler(entry.slug.current)
        }}
      >
        <td>{entry.year}</td>
        <td>{entry.title}</td>
        <td>{entry.chapter}</td>
        <td>
          <Img fixed={entry.image.asset.fixed} alt={entry.image.alt} />
        </td>
      </tr>
    ))
  }

  render() {
    const { rows } = this

    return (
      <ChampListStyles>
        <table>
          <thead>
            <tr>
              <th>Year</th>
              <th>Name</th>
              <th>Chapter</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>{rows.map(row => row)}</tbody>
        </table>
      </ChampListStyles>
    )
  }
}

export default function DesktopChamps({ entries }) {
  return <HallOfFameTableComponent entries={entries} />
}
